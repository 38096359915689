import React, {forwardRef, useEffect, useState} from "react";
import {Avatar, Group, Text, Select} from "@mantine/core";
import {AccountModel} from "../models/AccountModels";
import {DepositModel} from "../models/DepositModels";

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
}

interface DataProps {
    accounts: AccountModel[]
    deposit: DepositModel
    selectedBankName: string
    setSelectedBankName: (val: string) => void
}

export default function SelectInputBox({accounts, setSelectedBankName, selectedBankName, deposit}: DataProps) {
    // const [searchValue, onSearchChange] = useState('');
    const [banksSelectList, setBanksSelectList] = useState<string[]>([]);
    useEffect(() => {
        if ((deposit.paymentWayId == 2 && deposit.amount > 100000)) {
            let banksSL = accounts?.map((acc) => acc?.bank?.name)
            setBanksSelectList(banksSL);
        } else {
            if (accounts?.length > 0) {
                setBanksSelectList(deposit.paymentWayId === 1 ?
                    accounts.map(acc =>
                        acc.accountGroupId === 8
                            ? "Papara IBAN Oto"
                            : acc.accountGroup?.name)
                    : ["Tüm Bankalar - 7/24 FAST"])
                if (accounts.some(acc => acc.accountGroupId === 4) || accounts.some(acc => acc.accountGroupId === 8) ){
                    setSelectedBankName("")
                }
                else {
                    if (banksSelectList.length === 1 && banksSelectList[0] === 'Papara IBAN Oto')
                        setSelectedBankName('Papara IBAN Oto')
                    else
                        setSelectedBankName(deposit.paymentWayId === 1 ? "PAPARA" : "Tüm Bankalar - 7/24 FAST")
                }
            }
        }
        // setBanksSelectList(banksSL)
    }, [accounts]);


    return (
        <>
            <Select
                color={"#e7bf3e"}
                placeholder={deposit.paymentWayId === 1 ? "Papara & Papara (IBAN)" : "Banka"}
                label={deposit.paymentWayId === 1 ? "Yöntem Seçiniz" : "Banka Seçiniz"}
                // styles={() => ({item:{backgroundColor:'red'}})}
                value={(deposit.paymentWayId == 2 && deposit.amount >= 100000) || accounts.some(x => x.accountGroupId === 4)||accounts.some(x => x.accountGroupId === 8) ? selectedBankName :
                    banksSelectList[0]}
                // onSearchChange={onSearchChange}
                // searchValue={searchValue}
                nothingFound={deposit.paymentWayId === 1 ? "Uygun Hesap Bulunamadı" : "Uygun Banka Bulunamadı"}
                data={banksSelectList}
                onChange={e => setSelectedBankName(e as string)}
            />
        </>
    )
}

//
//
// import React, {forwardRef, useEffect, useState} from "react";
// import {Avatar, Group, Text, Select} from "@mantine/core";
// import {AccountModel} from "../models/AccountModels";
// import {DepositModel} from "../models/DepositModels";
//
// interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
//     image: string;
//     label: string;
//     description: string;
// }
//
// interface DataProps {
//     accounts: AccountModel[]
//     deposit: DepositModel
//     selectedBankName: string
//     setSelectedBankName: (val: string) => void
// }
//
// export default function SelectInputBox({accounts, setSelectedBankName,selectedBankName, deposit}: DataProps) {
//     // const [searchValue, onSearchChange] = useState('');
//     const [banksSelectList, setBanksSelectList] = useState<string[]>([]);
//     useEffect(() => {
//         if (deposit.paymentWayId == 2 && deposit.amount >= 20001) {
//             let banksSL = accounts?.map((acc) => acc.bank.name)
//             setBanksSelectList(banksSL);
//         } else {
//             if (accounts?.length > 0) {
//                 setBanksSelectList(deposit.paymentWayId === 1 ?
//                     // accounts.some(acc=>acc.accountGroupId===4)?["Papara","IBAN Papara"]:["Papar"]
//                     accounts.map(acc=>acc.accountGroup?.name)
//                     : ["Tüm Bankalar - 7/24 FAST"])
//                 if(deposit?.paymentWayId===2)
//                     setSelectedBankName(banksSelectList[0])
//             }
//         }
//         // setBanksSelectList(banksSL)
//     }, [accounts]);
//
//
//     return (
//         <>
//             <Select
//                 color={"#e7bf3e"}
//                 placeholder="Banka"
//                 label={deposit.paymentWayId === 1 ? "Hesap Seçiniz" : "Banka Seçiniz"}
//                 // styles={() => ({item:{backgroundColor:'red'}})}
//                 value={deposit.paymentWayId==2&&deposit.amount>=20001?selectedBankName:selectedBankName}
//                 // onSearchChange={onSearchChange}
//                 // searchValue={searchValue}
//                 nothingFound={deposit.paymentWayId === 1 ? "Uygun Hesap Bulunamadı" : "Uygun Banka Bulunamadı"}
//                 data={banksSelectList}
//                 onChange={e => setSelectedBankName(e as string)}
//             />
//         </>
//     )
// }